import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ElectronService } from 'ngx-electron';
import { TranslateService } from '@ngx-translate/core';
import { Device, HybridsSocketService, SocketAction } from 'hybrids-socket';
import { ColorService,
         SectionType,
         CommonStudentService,
         IpcStudentService,
         MonitorStudentService,
         SimulationStudentService,
         TemplateStudentService,
         Distribution,
         LanguageStudentService,
         SocketListenerStudentService } from 'hvita';
import { Network } from 'network';
import { VersionBoxService } from 'version-box';
import { ReceiverService } from 'hvita-receiver';
import { AppBase, ErrorType } from 'hybrids-config';
import { WaveStudentService } from 'patient-monitor';

/**
 * Componente que representa la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends AppBase
{
  environment = environment;

  socketInitialized = false;
  appInitialized = false;

  constructor(private router: Router,
              private languageService: LanguageStudentService,
              private translate: TranslateService,
              private colorService:ColorService,
              private hybridsSocketService:HybridsSocketService,
              private socketListenerService: SocketListenerStudentService,
              private versionBoxService:VersionBoxService,
              private receiverService: ReceiverService,
              private commonService:CommonStudentService,
              private monitorService:MonitorStudentService,
              private templateService:TemplateStudentService,
              private simulationService:SimulationStudentService,
              private waveService:WaveStudentService,
              public ipcService: IpcStudentService,
              public electronService:ElectronService)
  {
    super();

    //inicialización de student
    this.socketListenerService.initializeStudent.subscribe(() =>
    {
      this.socketInitialized = true;

      if(this.appInitialized)
        this.initializeStudent();
    });
  }

  /**
   * Ejecuta los procesos de inicialización de la aplicación
   * y añade las subscripciones necesarias para responder a
   * diversas acciones emitidas desde distintos componentes
   */
  async initialize()
  {
    try
    {
      //distribución visual de los paneles de simulación
      const distribution = new Array<Distribution>(
        new Distribution({
          section: SectionType.ECMO_FLOW,
          title : 'ecmo',
          keys : [['lpm', 'rpm'], ['pven', 'part'], ['ap', 'tven'], ['svo2', 'hbhc'], ['act', ''], ['gasflow', 'gasfio2']]
        }),
        new Distribution({
          section: SectionType.VENTILATION,
          title : 'respirador',
          keys : [['frpautado', 'frtotal', 'vtpautado', 'vtespirado', 'pplat', 'ie'], ['fio2', 'peep', 'pins', 'ps', 'ppeak', '']]
        }),
        new Distribution({
          section: SectionType.NEURO,
          title : 'neuro',
          keys : [['bis', 'nirs']]
        }),
        new Distribution({
          section: SectionType.MONITOR,
          title : 'monitor',
          keys : [['ecg', 'abp', 'pap', 'spo2', 'cvp']]
        })
      );

      //establecemos un idioma por defecto
      this.translate.use(environment.defaultLanguage);

      await this.ipcService.initializeData();

      this.receiverService.initialize();
      this.versionBoxService.setVersion(this.ipcService.info.software, this.ipcService.info.hardware);

      // await this.languageService.initializeLanguages();
      await this.templateService.initializeTemplates(distribution);
      await this.monitorService.initializeMonitor(distribution.find(m => m.section === SectionType.MONITOR).keys[0]);
      this.commonService.initializeCommon();

      //inicializamos el socket y el listener
      this.hybridsSocketService.initialize(environment.endPointSocket, environment.device);
      this.socketListenerService.initialize();

      // configuración inicial de la red wifi
      await Network.initialize(this.ipcService.info.ssid!, this.ipcService.info.password!, this.ipcService.localStorageWireless);

      if(this.electronService.isElectronApp) //en aplicaciomnes electron iniciamos el checkeo de redes
        await Network.check();
      else //en aplicaciones web abrimos la conexión socket
        this.hybridsSocketService.openConnection();

      this.appInitialized = true;

      if(this.socketInitialized)
        this.initializeStudent();
    }
    catch
    {
      this.router.navigate(['/', 'error', ErrorType.UNKNOW]);
    }
  }


  /**
   * Inicializa Student cargando los datos necesarios de Teacher
   */
  initializeStudent()
  {
    //obtenemos el idioma actual seleccionado en el profesor
    this.hybridsSocketService.sendAndRetrive(SocketAction.LANGUAGE, Device.TEACHER).then(lang =>
    {
      this.languageService.setEnabledLanguage(lang.data);

      this.hybridsSocketService.sendAndRetrive(SocketAction.COLORS_LIST, Device.TEACHER).then(colors =>
      {
        this.colorService.colors = colors.data;

        this.hybridsSocketService.sendAndRetrive(SocketAction.WAVES_LIST, Device.TEACHER).then(waves =>
        {
          this.monitorService.waves = waves.data;

          this.hybridsSocketService.sendAndRetrive(SocketAction.DAMPINGS_LIST, Device.TEACHER).then(dampings =>
          {
            this.monitorService.dampings = dampings.data;

            this.hybridsSocketService.sendAndRetrive(SocketAction.RHYTHMS_LIST, Device.TEACHER).then(rhtytms =>
            {
              this.monitorService.rhythms = rhtytms.data.rhythms;
              this.monitorService.rhythmsOptions = rhtytms.data.options;
              this.monitorService.selectRhythm = rhtytms.data.selected;

              this.hybridsSocketService.sendAndRetrive(SocketAction.PATIENT_VIEW_LIST, Device.TEACHER).then(views =>
              {
                this.monitorService.views = views.data.views;
                this.monitorService.selectViews = views.data.selected;

                this.hybridsSocketService.sendAndRetrive(SocketAction.DERIVATION_VARIABLES_LIST, Device.TEACHER).then(derivations =>
                {
                  this.monitorService.derivationVariables = derivations.data;

                  //inicializamos las curvas
                  this.waveService.initializeWaves();

                  this.hybridsSocketService.sendAndRetrive(SocketAction.TEMPLATES_LIST, Device.TEACHER).then(templates =>
                  {
                    templates.data.map(template =>
                    {
                      const preasure = template.templatesSections.find(m => m.idSection == SectionType.ECMO_PREASURE);
                      const flow = template.templatesSections.find(m => m.idSection == SectionType.ECMO_FLOW);

                      flow.visible = (!preasure.visible && !flow.visible) ? false : true;

                      template.templatesSections.splice(template.templatesSections.findIndex(m => m.idSection == SectionType.ECMO_PREASURE), 1);
                      template.templatesSections.splice(template.templatesSections.findIndex(m => m.idSection == SectionType.CONTROLLER), 1);
                    });

                    this.templateService.templates = templates.data;
                    this.templateService.template = templates.data.find(m => m.isDefault).cloneObject();

                    //cargamos la configuración de plantilla
                    this.templateService.loadTemplates();

                    this.hybridsSocketService.sendAndRetrive(SocketAction.ECMO_MODE, Device.TEACHER).then(mode =>
                    {
                      this.commonService.ecmoMode = mode.data;

                      this.hybridsSocketService.sendAndRetrive(SocketAction.INTERACTIVE_MODE, Device.TEACHER).then(mode =>
                      {
                        this.commonService.interactiveMode = mode.data;

                        this.hybridsSocketService.sendAndRetrive(SocketAction.WEB_SERVER_TEACHER, Device.TEACHER).then(url =>
                        {
                          this.ipcService.storageURL = url.data;

                          //obtenemos la configuración del muteado de alarmas
                          this.hybridsSocketService.sendAndRetrive(SocketAction.MUTE_STATUS, Device.TEACHER).then(muted =>
                          {
                            muted.data ? this.monitorService.mute() : this.monitorService.unmute();

                            //preguntamos al profesor si esta reproduciendo alguna simulacion
                            this.hybridsSocketService.sendAndRetrive(SocketAction.STAGE, Device.TEACHER).then(parameters =>
                            {
                              this.simulationService.loadStage(parameters.data);
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }
}
