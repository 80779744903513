<ion-app>
  <hvita-header logo="./hvita/assets/images/SVG/logo_grey.svg"
    burgerIcon="./hvita/assets/images/SVG/burger_enabled.svg"
    playIcon="./hvita/assets/images/SVG/play_disabled.svg"
    pauseIcon="./hvita/assets/images/SVG/pause_disabled.svg"
    reloadIcon="./hvita/assets/images/SVG/reload_disabled.svg"
    nextIcon="./hvita/assets/images/SVG/next_disabled.svg"
    configIcon="./hvita/assets/images/SVG/config_disabled.svg"
    templateIcon="./hvita/assets/images/SVG/test_enabled.svg">
  </hvita-header>
  <hvita-menu></hvita-menu>

  <ion-content>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-content>

  <jeep-sqlite></jeep-sqlite>
</ion-app>
