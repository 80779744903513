import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';

import { ElectronService } from 'ngx-electron';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';

import { NumberToTimePipe, ParameterToValuePipe, ColorToNamePipe, DateFormatPipe, HVitaModule } from 'hvita';
import { HVitaReceiverModule } from 'hvita-receiver';
import { HVitaMediaModule } from 'hvita-media';
import { HVitaParameterModule } from 'hvita-parameter';
import { HVitaStructureModule } from 'hvita-structure';
import { DialogBoxModule, DialogBoxConfig } from 'dialog-box';
import { HybridsSocketModule } from 'hybrids-socket';
import { HybridsRepoModule, RepoConfig, RepositoryType } from 'hybrids-repo';
import { HybridsMenuModule } from 'hybrids-menu';
import { HybridsConfig } from 'hybrids-config';
import { ModalModule } from 'modal';
import { ModalBoxModule, ModalBoxButton, ModalBoxEvent, ModalBoxClose } from 'modal-box';
import { SubscriptionModule } from 'subscription';
import { VersionBoxModule, VersionBoxConfig } from 'version-box';
import { WirelessModule } from 'wireless';
import { PatientMonitorModule } from 'patient-monitor';
import { global } from 'common';
import { HttpManagerModule, HttpManagerConfig } from 'http-manager';
import { HttpStorageModule, HttpStorageConfig } from 'http-storage';
import { LoadingModule, LoadingConfig } from 'loading';
import { KeyboardModule, KeyboardConfig } from 'keyboard';

global.init();

const config = new HybridsConfig({
  me: environment.device,
  endPointSocket: environment.endPointSocket,
  version: environment.version,
  defaultLanguage: environment.defaultLanguage,
  isVR: false,
  patientMonitorViews: 4
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ModalBoxModule.forRoot(),
    DialogBoxModule.forRoot(new DialogBoxConfig({
      accept: new ModalBoxButton({
        title: 'aceptar',
        event: ModalBoxEvent.ACCEPT
      }),
      cancel: new ModalBoxButton({
        title: 'cancelar',
        event: ModalBoxEvent.CANCEL
      })
    })),
    VersionBoxModule.forRoot(new VersionBoxConfig({
      close: new ModalBoxClose({
        event: ModalBoxEvent.CANCEL,
        icon: './hvita/assets/images/SVG/close_enabled.svg'
      }),
      image: './hvita/assets/images/SVG/isotype.svg'
    })),
    SubscriptionModule.forRoot(),
    HybridsRepoModule.forRoot(new RepoConfig({ repository: RepositoryType.SQLITE })),
    HybridsSocketModule.forRoot(),
    HybridsMenuModule.forRoot(),
    WirelessModule.forRoot(),
    HVitaReceiverModule.forRoot(),
    HVitaModule.forRoot(config),
    HVitaMediaModule.forRoot(config),
    HVitaParameterModule.forRoot(config),
    HVitaStructureModule.forRoot(config),
    PatientMonitorModule.forRoot(config),
    HttpManagerModule.forRoot(new HttpManagerConfig({
      defaultLanguage: environment.defaultLanguage
    })),
    KeyboardModule.forRoot(new KeyboardConfig({ active: true })),
    HttpStorageModule.forRoot(new HttpStorageConfig({})),
    LoadingModule.forRoot(new LoadingConfig({}))
  ],
  providers: [
    NumberToTimePipe,
    ParameterToValuePipe,
    ColorToNamePipe,
    DateFormatPipe,
    ElectronService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpBackend)
{
  return new MultiTranslateHttpLoader(http, [
     { prefix: './hvita/assets/i18n/', suffix: '.json' }, //hvita assets
     { prefix: './keyboard/assets/i18n/', suffix: '.json' }, //keyboard assets
     { prefix: './wireless/assets/i18n/', suffix: '.json' }, //wireless assets
     { prefix: './modal-box/assets/i18n/', suffix: '.json' }, //modal-box assets
     { prefix: './version-box/assets/i18n/', suffix: '.json' } //version-box assets
   ])
 }
